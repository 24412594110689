import { FirebaseApp, initializeApp } from 'firebase/app';
import { GoogleAuthProvider, Unsubscribe, User, UserCredential, getAuth, signInWithEmailLink, signInWithRedirect } from 'firebase/auth';
import { makeAutoObservable, runInAction } from 'mobx';
import { logDebug, logError } from '../shared/logger';
import { AttachmentFile, IEmailMetaData, IFilledTicSheetData, IJobResults, ITokenArrays, LineType, TemplateMetaData } from '../shared/Azure';
import RestAPI from '../utils/restAPI';
import SharedUtils, { Roles } from '../shared/SharedUtils';
import { IOrganizationObject, IOrganizationUser } from '../shared/interfaces';
import { appFeatures } from './AppFeatures';
import { v4 as uuidv4 } from 'uuid';
import { getFirestore } from 'firebase/firestore';


const estimaticsDevFBConfig = {
	apiKey: "AIzaSyC5mimsqMDAmMT657D4RQ27XK5OQn3g2QA",
	authDomain: "estimatics-dev.firebaseapp.com",
	projectId: "estimatics-dev",
	storageBucket: "estimatics-dev.appspot.com",
	messagingSenderId: "955718140737",
	appId: "1:955718140737:web:3075e99478153f151b97c0",
	measurementId: "G-KTC0E3TDBW",
	databaseURL: "https://estimatics-dev.firebaseio.com"
}
const estimaticsFBConfig = {
	apiKey: "AIzaSyAs11K8lcD_ih0JKN8dBotvQr5znXz_70g",
	authDomain: "estimatics-fc6eb.firebaseapp.com",
	projectId: "estimatics-fc6eb",
	storageBucket: "estimatics-fc6eb.appspot.com",
	messagingSenderId: "888966300616",
	appId: "1:888966300616:web:e928c9f55ce39aed48f6fd",
	measurementId: "G-Y7XEBJPC50",
	databaseURL: "https://estimatics-fc6eb.firebaseio.com"
}

const lassoMVPFBConfig = {
	apiKey: "AIzaSyDgXXTHgXeATlP6Uz3WrhzO83t2cvg43Oc",
	authDomain: "golasso-ebc2f.firebaseapp.com",
	projectId: "golasso-ebc2f",
	storageBucket: "golasso-ebc2f.appspot.com",
	messagingSenderId: "72541849319",
	appId: "1:72541849319:web:4fc91bd3aeb7d7d5590442",
	measurementId: "G-SD7Q6W7FG4"
}
const lassoFBConfig = {
	apiKey: "AIzaSyAXOEBFqZ4P-3Rqss06xZLKOVybGUX4_4w",
	authDomain: "lasso-a234c.firebaseapp.com",
	projectId: "lasso-a234c",
	storageBucket: "lasso-a234c.appspot.com",
	messagingSenderId: "367777088649",
	appId: "1:367777088649:web:1eabe82d8c675695d83258",
	measurementId: "G-5GF405JLG2"
};

export const firebaseConfig = appFeatures.isEstimatics ? (appFeatures.isDebug() ? estimaticsDevFBConfig : estimaticsFBConfig) : lassoMVPFBConfig;
logDebug('firebaseConfig', firebaseConfig);
interface ILassoUser extends User {
	organizationId: string;
	lassoUserId: string;
	role: string;
	isRegistered?: boolean;
	firstName?: string;
	lastName?: string;
}

export interface IWizardManager {
	get wizardTitles(): string[];
	wizardStep: number;
	// showProcessingAnimation: boolean;
}


export interface IEstimaticsData {
	estimateId: string;
	projectName: string;
	estimateReferenceNumber: string;
	requesterName: string;
	email: string;
	phone: string;
	propertyAddress: string;
	insuranceCarrier: string;
	claimNumber: string;
	tpa: string;
	dateCreated: string;
	dateContacted: string;
	dateOfLoss: string;
	office: string;
	assignee: string;
	status: string;
	approximateClaim: string;
	links: string[];
	notes: string[];
	files: AttachmentFile[];
	category: string;
	typeOfLoss: string;
	fieldData: string;
	deleted: boolean;
}


type EstimaticsDataStringKeys<T> = {
	[K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

// Use the mapped type to define your new type
export type EstimaticsDataKeys = EstimaticsDataStringKeys<IEstimaticsData>;

export enum EstimaticsFileTypes {
	tic = "tic", image = "image", other = "other"
}

interface IFilePropertiesForUpdate {
	remotePath?: string;
	mime?: string;
	uploadDate?: string;
	uploadBy?: string;
	error?: string;

}
class EstimaticsCurrentProjectStore implements IWizardManager {

	private _sortedData: IEstimaticsData[] = [];
	public get sortedData() {
		return this._sortedData;
	}
	public set sortedData(value: IEstimaticsData[]) {
		this._sortedData = [...value];
	}

	private _officeFilter: any = null;
	public get officeFilter() {
		return this._officeFilter;
	}
	public set officeFilter(value: any) {
		this._officeFilter = value;
	}

	private _searchFilter: string = '';
	public get searchFilter(): string {
		return this._searchFilter;
	}
	public set searchFilter(value: string) {
		this._searchFilter = value;
	}


	private _assigneeFilter: any = null;
	set assigneeFilter(val: any) {
		this._assigneeFilter = val;
	}
	get assigneeFilter() {
		return this._assigneeFilter;
	}


	private _statusFilter: any = null;
	set statusFilter(val: any) {
		this._statusFilter = val;
	}
	get statusFilter() {
		return this._statusFilter;
	}
	get wizardTitles(): string[] {
		return [
			"Project Details",
			"Documentation",

		];
	}
	private _nextDisabled = 0;
	public get nextDisabled() {
		return this._nextDisabled;
	}
	public set nextDisabled(value) {
		this._nextDisabled = value;
	}



	// private _showProcessingAnimation: boolean = false;
	// public get showProcessingAnimation() {
	// 	return this._showProcessingAnimation;
	// }
	// public set showProcessingAnimation(value: boolean) {
	// 	this._showProcessingAnimation = value;
	// }
	private _showLoadingAnimation: boolean = false;
	public get showLoadingAnimation(): boolean {
		return this._showLoadingAnimation;
	}
	public set showLoadingAnimation(value: boolean) {
		this._showLoadingAnimation = value;
	}
	private _estimaticsData: IEstimaticsData = EstimaticsCurrentProjectStore._createNewEstimaticsData('');


	async deleteEstimate() {
		this.showLoadingAnimation = true;
		try {
			const dataToUpload = {
				estimateId: this._estimaticsData.estimateId,
				deleted: true
			};
			const reply = await RestAPI.fetchAPI('/rest/estimates/updateEstimaticsEstimate', 'POST', { estimaticsData: dataToUpload });
		} catch (err) {

		}
		this.showLoadingAnimation = false;
	}


	async uploadEstimate(keytoUpdate?: string, forceAnimation?: boolean, disableAlert?: boolean) {
		let ret = true;
		this.showLoadingAnimation = !keytoUpdate || !!forceAnimation;
		try {



			if (!keytoUpdate) {
				if (!this._estimaticsData.projectName.trim()) {
					// TODO :: this is some odd back that wizard step 3 fires update after closing the modal and going back to project's list
					return;
				}
				// trim all string values
				Object.keys(this._estimaticsData).forEach((key: string) => {
					if (typeof this.getValue(key) === 'string') {
						this.setValue(key, this.getValue<string>(key).trim());
					}
				});
				const reply = await RestAPI.fetchAPI('/rest/estimates/createEstimaticsEstimate', 'POST', { estimaticsData: this._estimaticsData });
				if (!reply) {
					ret = false;
				}
			} else {
				if (typeof this.getValue(keytoUpdate) === 'string') {
					this.setValue(keytoUpdate, this.getValue<string>(keytoUpdate).trim());
				}
				const valueToUpdate = this.getValue(keytoUpdate);
				const dataToUpload = {
					estimateId: this._estimaticsData.estimateId,
					[keytoUpdate]: valueToUpdate
				};
				const reply = await RestAPI.fetchAPI('/rest/estimates/updateEstimaticsEstimate', 'POST', { estimaticsData: dataToUpload });
				if (!reply) {
					ret = false;
				}
			}
		} catch (err) {
			ret = false;
		}
		if (!ret) {
			if (!disableAlert) {
				// TODO :: notify the user in the wizard
				alert('Failed to update estimate');
			}
		}
		this.showLoadingAnimation = false;
		return ret;
	}

	private static _createNewEstimaticsData(estimateId: string) {
		return {
			dateCreated: new Date().toLocaleDateString('en-US'),
			estimateId,
			projectName: '',
			estimateReferenceNumber: '',
			requesterName: '',
			email: '',
			phone: '',
			propertyAddress: '',
			insuranceCarrier: '',
			claimNumber: '',
			tpa: '',
			dateContacted: '',
			dateOfLoss: '',
			office: '',
			approximateClaim: '',
			links: [],
			notes: [],
			files: [],
			fieldData: '',
			status: '',
			assignee: '',
			category: '',
			typeOfLoss: '',
			deleted: false,
		};
	}

	async setValue<T>(propertyName: string, v: T, uploadData?: boolean) {
		// @ts-ignore
		this._estimaticsData[propertyName] = v;
		if (uploadData) {
			await this.uploadEstimate(propertyName);
		}
	}
	getValue<T>(propertyName: string): T {
		// @ts-ignore
		return (this._estimaticsData[propertyName] as T) ?? '';
	}


	updateEstimaticsDataFileProperties(uploadId: string, props: IFilePropertiesForUpdate = {}) {
		const index = this._estimaticsData.files.findIndex(f => f.uploadId === uploadId);
		if (index === -1) return;
		this._estimaticsData.files[index] = {
			...this._estimaticsData.files[index],
			...props
		};
	}
	addFile(newFile: {
		uploadId: string,
		originalname: string,
		uploadedSofar: number,
		size: number,
		fileType: EstimaticsFileTypes,
		uploadDate: string,
		uploadBy: string,
	}) {
		this._estimaticsData.files.push(newFile);
	}
	updateEstimaticsDataFileDeleted(uploadId: string, deleted: boolean, uploadData?: boolean, forceAnimation?: boolean) {
		const index = this._estimaticsData.files.findIndex(f => f.uploadId === uploadId);
		if (index === -1) return;
		this._estimaticsData.files[index].deleted = deleted;
		if (uploadData) {
			this.uploadEstimate('files', forceAnimation);
		}

	}

	removeLink(index: number) {
		this._estimaticsData.links.splice(index, 1);
	}

	pushLink(link: string) {
		this._estimaticsData.links.push(link);
	}
	pushLinkFront(link: string) {
		if (this._estimaticsData.links.length > 0 && this._estimaticsData.links[0] === '') {
			return;
		}
		this._estimaticsData.links.unshift(link);

	}
	setLink(index: number, link: string) {
		this._estimaticsData.links[index] = link;
	}
	removeNote(index: number) {
		this._estimaticsData.notes.splice(index, 1);
	}

	pushNoteFront(note: string) {
		if (this._estimaticsData.notes.length > 0 && this._estimaticsData.notes[0] === '') {
			return;
		}
		this._estimaticsData.notes.unshift(note);
	}

	pushNote(note: string) {
		this._estimaticsData.notes.push(note);
	}
	setNote(index: number, note: string) {
		this._estimaticsData.notes[index] = note;
	}

	updateEstimaticsDataFileProgress(uploadId: string, progress: number) {
		const file = this._estimaticsData.files.find(f => f.uploadId === uploadId);
		if (!file) return;
		file.uploadedSofar = progress;
	}
	public get newEstimateID(): string {
		return this.estimaticsData.estimateId;
	}
	// public set newEstimateID(value: string) {
	// 	this._newEstimateID = value;
	// }
	public get estimaticsData(): IEstimaticsData {
		return this._estimaticsData;
	}
	public set estimaticsData(value: IEstimaticsData) {
		this._estimaticsData = value;
	}
	public _wizardStep: number = -1;
	public get wizardStep() {
		return this._wizardStep;
	}
	public set wizardStep(value: number) {
		if (value < 0) {
			this.showNewProjectModal = false;
		}
		this._wizardStep = value;
	}

	private _showNewProjectModal = false;
	public get showNewProjectModal() {
		return this._showNewProjectModal;
	}
	public set showNewProjectModal(value: boolean) {
		if (value) {
			this._estimaticsData = EstimaticsCurrentProjectStore._createNewEstimaticsData(uuidv4());
			this._wizardStep = 1;
		} else {
			this._estimaticsData = EstimaticsCurrentProjectStore._createNewEstimaticsData('');
		}
		this._showNewProjectModal = value;
	}


	constructor() {
		makeAutoObservable(this);
	}

}

class AppStore {
	private _estimates: IEstimaticsData[] = [];
	set estimates(data: IEstimaticsData[]) {
		this._estimates = data;
	}
	get estimates() {
		return this._estimates;
	}


	private breadcrumb: string[] | undefined = undefined;


	private _orgnizations: IOrganizationObject[] = [];
	private _selectedOrganization: IOrganizationObject | null = null;

	public get organizations() {
		return this._orgnizations;
	}

	public get selectedOrganization() {
		return this._selectedOrganization;
	}

	public setCustomeSettings(value: string) {
		this._selectedOrganization!.customSettings = value;
	}

	constructor() {
		makeAutoObservable(this);
	}

	public get breadcrumbPath() {
		return this.breadcrumb;
	}
	public get breadcrumbPathSafe() {
		return this.breadcrumb || [];
	}

	public setBreadcrumbPath(path: string[] | string) {
		if (typeof path === 'string')
			this.breadcrumb = [path];
		else
			this.breadcrumb = path;
		// logDebug('breadcrumb', this.breadcrumb);
	}

	public pushBreadcrumbPath(path: string[] | string) {
		this.breadcrumb = this.breadcrumb || [];
		if (typeof path === 'string')
			this.breadcrumb.push(path);
		else
			this.breadcrumb.push(...path);
	}

	public popBreadcrumbPath() {
		this.breadcrumb?.pop();
	}

	public set selectedOrganizationName(name: string) {
		if (!this._selectedOrganization) return;
		this._selectedOrganization.organizationName = name;
	}
	public set selectedOrganizationDescription(description: string) {
		if (!this._selectedOrganization) return;
		this._selectedOrganization.organizationDescription = description;
	}
	public set selectedOrganizationAddress(address: string) {
		if (!this._selectedOrganization) return;
		this._selectedOrganization.organizationAddress = address;
	}
	public set selectedOrganizationLogo(logo: string) {
		if (!this._selectedOrganization) return;
		this._selectedOrganization.organizationLogo = logo;
	}
	public set selectedOrganizationUsers(users: IOrganizationUser[]) {
		if (!this._selectedOrganization) return;
		this._selectedOrganization.users = [...users];
	}
	public set selectedOrganizationCustomSettings(settings: string) {
		if (!this._selectedOrganization) return;
		this._selectedOrganization.customSettings = settings;
	}

	public set selectedOrganizationAIModels(models: any) {
		if (!this._selectedOrganization) return;
		this._selectedOrganization.aiModels = models;
	}
	public set selectedOrganizationAllowedDomain(domain: string) {
		if (!this._selectedOrganization) return;
		this._selectedOrganization.allowedDomain = domain;
	}

	setNewOrganization() {
		runInAction(() => {
			//@ts-ignore
			this._selectedOrganization = {
				aiModels: JSON.stringify(JSON.parse(`{
					"layout": "defaultsss",
					"classification": "default"
				}`), null, 2),
				organizationName: '',
				organizationDescription: '',
				organizationAddress: '',
				organizationLogo: '',
				//@ts-ignore
				users: appFeatures.isEstimatics ? [{
					role: Roles.ADMIN_ROLE,
					email: `dummy-email-${new Date().getTime()}@golasso.ai`,
					firstName: 'non-existing',
					lastName: 'dummy',
				}] : [],
				allowedDomain: '',
			};
		});
	}


	setSelectedOrganization(org: IOrganizationObject) {
		runInAction(() => {
			this._selectedOrganization = {
				...org,
				aiModels: JSON.stringify(org.aiModels ?? '{}', null, 2),
				customSettings: JSON.stringify(org.customSettings ?? '{}'),
			};
		});
	}
	public async loadOrganizations(force: boolean = false) {
		if (!force && this._orgnizations.length > 0) {
			return;
		}
		const userStore = getUserStore();
		if (!userStore.isUserCreator) {
			throw new Error('User is not a creator');
		}
		try {
			const response = await RestAPI.fetchAPI('/rest/access/listOrganizations', 'GET');
			if (response.organizations) {
				runInAction(() => {
					this._orgnizations = response.organizations;
				});
			}
		} catch (err) {
			logError('Error listing organizations', err);
		}
	}
}

interface IDBUser {

	email: string,
	firstName: string,
	lassoUserId: string,
	lastName: string,
	photoURL: string,
	role: string,

}
class UserStore {
	public appReady = false;
	public user: ILassoUser | null = null;
	private _firebaseApp: FirebaseApp;
	private _unsubscribe: Unsubscribe;

	private _customSettings: any;
	public get customSettings(): any {
		return this._customSettings;
	}
	private set customSettings(value: any) {
		this._customSettings = value;
	}

	private _extraFields: string[] = [];
	public get extraFields(): string[] {
		return this._extraFields;
	}
	private set extraFields(value: string[]) {
		this._extraFields = value;
	}

	private _tpaList: string[] = [];
	public get tpaList(): string[] {
		return this._tpaList;
	}
	private set tpaList(value: string[]) {
		this._tpaList = value;
	}

	private _carrierList: string[] = [];
	public get carrierList(): string[] {
		return this._carrierList;
	}
	private set carrierList(value: string[]) {
		this._carrierList = value;
	}
	private _officeList: string[] = [];
	public get officeList(): string[] {
		return this._officeList;
	}
	private set officeList(value: string[]) {
		this._officeList = value;
	}
	private _otherUserList: IDBUser[] = [];
	// public get otherUserList() {
	// 	return this._otherUserList;
	// }
	private set otherUserList(value: IDBUser[]) {
		this._otherUserList = value;
	}
	async logout() {
		await getAuth().signOut();
	}

	public getOtherUserList(allowEmpty: boolean) {
		if (allowEmpty) {
			return [{ lassoUserId: '', firstName: '', lastName: '', email: '', photoURL: '', role: '' }, ...this._otherUserList];
		}
		return this._otherUserList;
	}

	public getOtherUserById(userId: string, allowEmpty: boolean = false) {
		const users = this.getOtherUserList(allowEmpty);
		const user = users.find((user) => user.lassoUserId === userId);
		// logDebug('getOtherUserById', userId, user, SharedUtils.clone(users));
		return user;
	}

	public get firebase() {
		return this._firebaseApp;
	}

	constructor() {
		logDebug('UserStore constructor');
		makeAutoObservable(this);

		// debugger;
		this._firebaseApp = initializeApp(firebaseConfig);
		const db = getFirestore(this._firebaseApp);
		// const databaseName = 'estimatics-dev-private'; // Replace with your database name
		// const newDb = getFirestore(this._firebaseApp, `https://${firebaseConfig.projectId}.firebaseio.com/${databaseName}`);

		// this._firebaseApp = initializeApp(firebaseConfig, 'estimatics-dev-private');
		const auth = getAuth();
		this._unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				//logDebug('LassoPage', user);
				await user.getIdToken(true);
				this._setUser(user);
			} else {
				logDebug('no user')
				if (location.hostname === 'tools.golasso.ai' || location.href.includes('tools=1')) {
					return;
				}
				const _href = location.href.toLowerCase();
				if (_href.includes('apikey=') && _href.includes('mode=signin') && _href.includes('email=')) {
					this._signIn();
				}
				else {
					if (location.pathname != '/' && !location.href.includes('disable-dev-private')) {
						window.localStorage.setItem('loginRedirect', location.href);
						location.href = '/';
					}
					this._setAppReady(true);
				}
				// if (!location.href.includes('/mvp/landing')) {
				// 	// navigationService.goTo('http://localhost:3000/mvp/landing');
				// }
				// this._signIn();
			}
		});

	}

	private async setCookies(user: User) {
		const tok = await user.getIdToken(true);
		document.cookie = `idToken=${tok};max-age=3600;SameSite=Strict`;
		document.cookie = `idToken_uid=${user.uid};max-age=3600;SameSite=Strict`;
	}

	private async _signIn() {
		try {
			let email = window.localStorage.getItem('passwordless_email');
			window.localStorage.removeItem('passwordless_email');
			if (!email) {
				// read from url
				const url = new URL(location.href);
				email = url.searchParams.get('email');
				if (!email) {
					logError('no email to sign in with');
					this._setAppReady(true);
					// TODO :: notify the user
					return;
				}
			}
			const user = await signInWithEmailLink(getAuth(), email, location.href);
			logDebug('signed in', user);
			await this.setCookies(user.user!);
			const loginRedirect = window.localStorage.getItem('loginRedirect');
			if (loginRedirect) {
				window.localStorage.removeItem('loginRedirect');
				location.href = loginRedirect;
			} else {
				location.href = '/';
			}
		} catch (err) {
			logError('UserStore', err);
			window.location.href = '/?expired-token=true';
		}
	}
	public async signInWithRedirect(): Promise<UserCredential> {
		const provider = new GoogleAuthProvider();
		const user = await signInWithRedirect(getAuth(), provider);
		this.user = user;
		return user;
	}

	private _setAppReady(value: boolean) {
		runInAction(() => {
			this.appReady = value;
		});
	}

	private _typeOfLossList: { type: string; categories?: string[] }[] = [
		{ type: "Other –" },
		{ type: "Rebuild/Reconstruction" },
		{ type: "Water", categories: ["Other –", "Cat 1", "Cat 2", "Cat 3"] },
		{ type: "Structural cleaning (fire)" },
		{ type: "Asbestos" },
		{ type: "Mold" },
		{ type: "Board up Temp" },
		{ type: "Forensic" },
		{ type: "Control Estimate" },
	];
	public get typeOfLossList(): { type: string; categories?: string[] }[] {
		return this._typeOfLossList;
	}
	private set typeOfLossList(value: { type: string; categories?: string[] }[]) {
		this._typeOfLossList = value;
	}

	get isUserAdmin() {
		return this.user?.role === Roles.ADMIN_ROLE ;
	}
	get isUserCreator() {
		return this.user?.role === Roles.CREATOR_ROLE ;
	}
	get isUserSimple() {
		return this.user?.role === Roles.USER_ROLE ;
	}


	updateRegisteredUser(newUserData: {
		firstName: string,
		lastName: string,
		photoURL: string,
	}
	) {
		if (!this.user) {
			logError('no user to update');
			return;
		}
		runInAction(() => {
			this.user = {
				...this.user!,
				...newUserData,
				isRegistered: true
			};
		});
	}


	private _extraData: { [k: string]: any; } = {};
	public get extraData(): { [k: string]: any; } {
		return this._extraData;
	}
	// public set extraData(value: { [k: string]: any; }) {
	// 	this._extraData = value;
	// }



	private async _setUser(user: User) {
		try {
			await this.setCookies(user);
			const userRole = await RestAPI.fetchAPI('/rest/access/getUserDetails', 'POST', {
				extraData: {
					fetchCarrierList: appFeatures.isEstimatics,
					fetchOfficeList: appFeatures.isEstimatics,
					fetchOtherUserList: appFeatures.isEstimatics,
					fetchCustomSettings: appFeatures.isEstimatics,
				}
			});
			if (!userRole) {
				// TODO :: notify the user
				logError('no user role');
				this._setAppReady(true);
				return;
			}


			runInAction(() => {
				this.user = {
					...user,
					organizationId: userRole.organizationId,
					lassoUserId: userRole.lassoUserId,
					role: userRole.role,
					isRegistered: userRole.isRegistered,
					firstName: userRole.firstName,
					lastName: userRole.lastName,
					photoURL: userRole.photoURL
				};
				this.carrierList = userRole.extraData.carrierList ?? [];
				this.officeList = userRole.extraData.officeList ?? [];
				this.otherUserList = userRole.extraData.otherUserList ?? [];
				this.tpaList = userRole.extraData.tpaList ?? [];
				this.customSettings = typeof userRole.extraData.customSettings === 'string' ? JSON.parse(userRole.extraData.customSettings) : userRole.extraData.customSettings ?? {};
				this.extraFields = this.customSettings?.extraFields ?? [];
				logDebug('user with props', this.user, this._customSettings, this._extraFields);
			});
		} catch (err) {
			logError('UserStore', err);
			// TODO :: notify the user
		}
		this._setAppReady(true);
	}

}

class EstimateStore {
	public exportState: number = -1;
	public exportMessage: string = '';
	public estimateID: string = '';
	public processID: string = '';
	public pages: LineType.Page[] = [];
	private _currentView: string = 'Estimate';
	public get currentView(): string {
		return this._currentView;
	}
	// public set currentView(value: string) {

	// 	this._currentView = value;
	// }
	private _ticSheetHighlight: number[] = [];
	public selectedPage: number = 0;
	public email: string = 'yakir@golasso.ai';
	public phone: string = '(123) 456-7890';
	public insured: string = 'Yakir Elkayam';
	public claimNumber: string = '12345678909876';
	public insuredLast: string = 'Elkayam';
	public creatingPDF: boolean = false;
	// Today - 7 days;
	public dateContacted = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US');
	public dateOfLoss = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US');
	public dateEstimateComplete = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US');
	public insuranceCarrier = 'State Farm';
	public rawSheetData: LineType.Page[] = [];
	public templateMetaData: TemplateMetaData | null = null;
	public filledTicSheetData: IFilledTicSheetData[][] = [];
	public jobResults: IJobResults | null = null;
	private _pageImageURL: string | undefined = '';
	public allFiles: AttachmentFile[] = [];
	private _netClaim: number = 123456;
	public dimensions: { w: number, h: number }[] = Array(100).fill({ w: 0, h: 0 });
	public updateLineItemExtracted(page: number, index: number, key?: ITokenArrays, value?: ITokenArrays, selected?: ITokenArrays) {
		value && (this.pages[page].lineItems[index].matchCell!.templateTuple.meta!.extracted!.extractedValueInput = value!);
		key && (this.pages[page].lineItems[index].matchCell!.templateTuple.meta!.extracted!.extractedKeyInput = key!);
		selected && (this.pages[page].lineItems[index].matchCell!.templateTuple.meta!.extracted!.extractedSelectedWords = selected!);
	}

	public setEmailMeta(meta?: IEmailMetaData) {
		if (!meta) return;
		this.email = meta.email;
		this.phone = meta.phone;
		this.insured = meta.insured;
		this.claimNumber = meta.claim;
		this.insuredLast = this.insured.split(/\s+/).pop() ?? '';
		// this.insuredLast = meta.insuredLast;
		// this.dateContacted = meta.dateContacted;
		// this.dateOfLoss = meta.dateOfLoss;
		// this.dateEstimateComplete = meta.dateEstimateComplete;
		// this.insuranceCarrier = meta.insuranceCarrier;

	}

	setCreatingPDF(value: boolean) {
		this.creatingPDF = value;
	}




	public setAllFiles(value: AttachmentFile[]) {
		this.allFiles = value;
	}

	public setFilledTicSheetData(value: IFilledTicSheetData[][]) {
		this.filledTicSheetData = value;
	}


	public setJobResults(value: IJobResults) {
		this.jobResults = value;
	}

	public setRawSheetData(rawSheetData: LineType.Page[]) {
		this.rawSheetData = rawSheetData;
	}

	public setTemplateMetaData(templateMetaData: TemplateMetaData) {
		this.templateMetaData = templateMetaData;
	}


	public get netClaim() {
		return this._netClaim;
	}

	constructor() {
		logDebug('EstimateStore constructor');
		makeAutoObservable(this);
	}

	public setRoomDimensions(index: number, dimensions: { w: number, h: number }) {
		this.dimensions[index] = { ...dimensions };
	}

	public calculateTotal() {
		//@ts-ignore
		this._netClaim = Array.from(document.querySelectorAll('.cellIndex-7')).map(val => val.innerText).map(val => parseFloat(val)).reduce((a, b) => a + b, 0);

	}
	public getRelativePageIndex(page: number) {
		if (!this.jobResults) return page;
		if (this.jobResults.pdfResults.length === 0) return page;
		let index = 0;
		let runningIndex = 0;
		do {
			const _runningIndex = runningIndex + this.jobResults!.pdfResults[index].pages.length;
			if (page < _runningIndex) {
				return page - runningIndex;
			}
			runningIndex = _runningIndex;
		} while (index < this.jobResults!.pdfResults.length);
		return page;
	}

	public getPdfIdAndPageFromPageIndex(page: number): {
		pdfID?: string;
		page?: LineType.Page;
	} {
		if (!this.jobResults) return {};
		if (this.jobResults.pdfResults.length === 0) return {};
		let index = 0;
		let runningIndex = 0;
		do {
			const _runningIndex = runningIndex + this.jobResults!.pdfResults[index].pages.length;
			if (page < _runningIndex) {
				return {
					pdfID: this.jobResults!.pdfResults[index].pdfID,
					page: this.jobResults!.pdfResults[index].pages[page - runningIndex]
				}
			}
			index++;
			runningIndex = _runningIndex;
		} while (index < this.jobResults!.pdfResults.length);
		return {};
	}

	public get pageImageURL() {
		return this._pageImageURL;
	}
	public get ticSheetHighlight() {
		return this._ticSheetHighlight;
	}

	public setCurrentView(view: string, pageImageURL?: string, highlight?: number[]) {
		this._currentView = view;
		this._pageImageURL = pageImageURL;
		this._ticSheetHighlight = highlight ?? [];
	}


	public addLineItem(index: number, lineItem: LineType.Line) {
		this.pages[index].lineItems.push(lineItem);
	}
	public setEstimateID(estimateID: string) {
		this.estimateID = estimateID;
	}

	public setProcessID(processID: string) {
		this.processID = processID;
	}

	public setPages(pages: LineType.Page[]) {
		this.pages = pages;
	}

	public setSelectPage(page: number) {
		this.selectedPage = page;
	}


	public getDebris(page: number) {
		if (!this.pages.length) return [];
		return this.pages[page].header?.debris;
	}

	public get address() {
		if (!this.pages.length) return '';
		return this.pages[0].header?.address.map((c) => c.content).join(' ').replace('Address', '').replaceAll(':', '').trim();
	}

	public get cityAndState() {
		return 'New York, NY, 10001';
	}

	public get roomNames() {
		return this.pages.map((page) => {
			const roomName = page.header?.room.map((c) => c.content).join(' ').replace('ROOM', '').replace(':', '').replace(';', '').trim();
			if (!roomName) {
				return 'Missing Room Name'
			}
			if (roomName?.length < 3) {
				return 'Missing Room Name'
			}
			return roomName;
		});
	}

	public get estimateRef() {
		if (!this.pages.length) return '';
		let dash = this.pages[0].header?.dash.map((c) => c.content).join(' ').replace('Dash', '').replaceAll('#', '') ?? '';
		// remove all empty spaces
		dash = dash.replace(/\s/g, '');
		return dash;

	}
}





const _appStore: AppStore = new AppStore();
const getAppStore = () => _appStore;

const _userStore: UserStore = new UserStore();
const getUserStore = () => _userStore;
export type UserStoreType = ReturnType<typeof getUserStore>;

const _estimateStore: EstimateStore = new EstimateStore();
const getEstimateStore = () => _estimateStore;

const _estimaticsCurrentProjectStore: EstimaticsCurrentProjectStore = new EstimaticsCurrentProjectStore();
const getEstimaticsCurrentProjectStore = () => _estimaticsCurrentProjectStore;
export type EstimaticsProjectStoreType = ReturnType<typeof getEstimaticsCurrentProjectStore>;


export const debugStyle = (style: string, color: string) => {
	const s = style + (SharedUtils.showDebug ? ` ${color}` : '');
	// logDebug('s', s, style);
	return s;
}

export {
	getEstimaticsCurrentProjectStore,
	getAppStore,
	getEstimateStore,
	getUserStore
};
